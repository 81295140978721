.footer {
  background-color: #1a202c; /* Darker background */
}

.footer h6 {
  color: #ffffff; /* White text for headers */
}

.footer a {
  transition: color 0.3s; /* Smooth color transition for links */
}

.footer a:hover {
  text-decoration: underline; /* Underline on hover */
}
